/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image, ContactForm, PriceWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Cukrárna"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1fzyb6i css-mlpdok --style2 --full pb--60 pt--60" name={"rnx2ur1ey2"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=3000x_.jpg);
    }
  
background-position: 49% 59%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white)\">Naše cukrárna</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"ynf375qnhdp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left" style={{"maxWidth":1138}} content={"<span style=\"color: rgb(0, 0, 0);\">Pravidelně do našeho bistra a kavárny pečeme kremrole, věnečky, dorty, cheesecaky,\nmini zákusky a koláče. Vše si můžete vychutnat v našich kavárnách, nebo si vzít s sebou.&nbsp;Při výrobě se držíme tradičních receptur propojených s&nbsp;prvky moderní cukrařiny.<br><br>Ochutnejte naše dezerty v bistru nebo si je objednejte domů třeba na narozeninovou\noslavu. Pro objednávky dortů nás kontaktujte na adrese info@bistronarodnimuzeum.cz</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"alskrly3nhq"} style={{"backgroundColor":"rgba(96, 96, 96, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":431}} content={"<span style=\"font-weight: bold;\">Mrkvový dort</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/1f7073994f274c03a3167bc88d7561ce_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/1f7073994f274c03a3167bc88d7561ce_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/1f7073994f274c03a3167bc88d7561ce_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/1f7073994f274c03a3167bc88d7561ce_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/1f7073994f274c03a3167bc88d7561ce_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/1f7073994f274c03a3167bc88d7561ce_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(38, 38, 38);\">Cena: 720 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12 w--400 ls--0 lh--08" content={"Alergeny:&nbsp;(1,3,5,7,8) | Velikost 24 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Sacherův dort</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/7b8b8779647a409fb48860445e4e1fc4_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/7b8b8779647a409fb48860445e4e1fc4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/7b8b8779647a409fb48860445e4e1fc4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/7b8b8779647a409fb48860445e4e1fc4_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/7b8b8779647a409fb48860445e4e1fc4_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/7b8b8779647a409fb48860445e4e1fc4_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 690 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12 lh--08" content={"Alergeny:&nbsp;(1,3,7) | Velikost 24 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Raffaelo dort (kokosový)</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/0783c36c0517438ea80ed9c86eb3ef32_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/0783c36c0517438ea80ed9c86eb3ef32_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/0783c36c0517438ea80ed9c86eb3ef32_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/0783c36c0517438ea80ed9c86eb3ef32_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/0783c36c0517438ea80ed9c86eb3ef32_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/0783c36c0517438ea80ed9c86eb3ef32_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(0, 0, 0);\">Cena: 975 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12 lh--14" content={"Alergeny: (1,3,7,8) | Velikost 24 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Mangový cheesecake</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/7aa3f6d78c3f42e5af385edc19d8c46c_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/7aa3f6d78c3f42e5af385edc19d8c46c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/7aa3f6d78c3f42e5af385edc19d8c46c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/7aa3f6d78c3f42e5af385edc19d8c46c_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/7aa3f6d78c3f42e5af385edc19d8c46c_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/7aa3f6d78c3f42e5af385edc19d8c46c_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 lh--08" content={"<span style=\"color: rgb(38, 38, 38);\">Cena: 740 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12 lh--14" content={"Alergeny: (1,3,7) | Velikost 24 cm | možno bezlepkový"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Mascarpone s čerstvými malinami</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/30d3e9874b2440afa1ff747a579734fd_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/30d3e9874b2440afa1ff747a579734fd_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/30d3e9874b2440afa1ff747a579734fd_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/30d3e9874b2440afa1ff747a579734fd_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/30d3e9874b2440afa1ff747a579734fd_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/30d3e9874b2440afa1ff747a579734fd_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 1 450 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12 lh--14" content={"Alergeny: (1,3,7) | Velikost 26 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Pistáciový cheesecake</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/77efbcf5ceae46569b1c14bfee11dec4_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/77efbcf5ceae46569b1c14bfee11dec4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/77efbcf5ceae46569b1c14bfee11dec4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/77efbcf5ceae46569b1c14bfee11dec4_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/77efbcf5ceae46569b1c14bfee11dec4_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/77efbcf5ceae46569b1c14bfee11dec4_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 890 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12 lh--14" content={"Alergeny: (1,3,7,8) | Velikost 24 cm | možno bezlepkový"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Cheesecake</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/42bf4a0fe63c411f851bcf3504212e19_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/42bf4a0fe63c411f851bcf3504212e19_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/42bf4a0fe63c411f851bcf3504212e19_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/42bf4a0fe63c411f851bcf3504212e19_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/42bf4a0fe63c411f851bcf3504212e19_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/42bf4a0fe63c411f851bcf3504212e19_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" style={{"maxWidth":421,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Cena: 690 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7) | Velikost 24 cm | možno bezlepkový"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Míša dort bezlepkový</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/bdd36d4b5a4e439c9e8bc4845ae1d66c_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/bdd36d4b5a4e439c9e8bc4845ae1d66c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/bdd36d4b5a4e439c9e8bc4845ae1d66c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/bdd36d4b5a4e439c9e8bc4845ae1d66c_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/bdd36d4b5a4e439c9e8bc4845ae1d66c_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/bdd36d4b5a4e439c9e8bc4845ae1d66c_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 740 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7) | Velikost 24 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Pařížský dort</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/f6ca128a7cdb4750b2cdea5951a83057_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/f6ca128a7cdb4750b2cdea5951a83057_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/f6ca128a7cdb4750b2cdea5951a83057_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/f6ca128a7cdb4750b2cdea5951a83057_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/f6ca128a7cdb4750b2cdea5951a83057_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/f6ca128a7cdb4750b2cdea5951a83057_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 768 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7) | Velikost 24 cm"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":431}} content={"<span style=\"font-weight: bold;\">Pečený cheesecake s les. ovocem</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/232c5cf39723467386fbfe1ed4a8c265_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/232c5cf39723467386fbfe1ed4a8c265_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/232c5cf39723467386fbfe1ed4a8c265_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/232c5cf39723467386fbfe1ed4a8c265_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/232c5cf39723467386fbfe1ed4a8c265_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/232c5cf39723467386fbfe1ed4a8c265_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 890 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7,8) | Velikost 24 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Lemon Pie</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/00d3ae0fd1594ad4abe825734d57e8bf_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/00d3ae0fd1594ad4abe825734d57e8bf_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/00d3ae0fd1594ad4abe825734d57e8bf_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/00d3ae0fd1594ad4abe825734d57e8bf_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/00d3ae0fd1594ad4abe825734d57e8bf_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/00d3ae0fd1594ad4abe825734d57e8bf_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 650 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7) | Velikost 26 cm | možno bezlepkový"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Drobenkový</span> <span style=\"font-weight: bold;\">koláč s ovocem</span><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/cfe6b4044e7d4ae787c9ce7f5a2b41dd_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/cfe6b4044e7d4ae787c9ce7f5a2b41dd_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/cfe6b4044e7d4ae787c9ce7f5a2b41dd_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/cfe6b4044e7d4ae787c9ce7f5a2b41dd_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/cfe6b4044e7d4ae787c9ce7f5a2b41dd_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/cfe6b4044e7d4ae787c9ce7f5a2b41dd_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(0, 0, 0);\">Cena: 450 Kč</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7) | Velikost 25 cm"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Větrník</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/18d351407a3e463c9649cdda7374b972_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/18d351407a3e463c9649cdda7374b972_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/18d351407a3e463c9649cdda7374b972_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/18d351407a3e463c9649cdda7374b972_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/18d351407a3e463c9649cdda7374b972_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/18d351407a3e463c9649cdda7374b972_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 lh--08" content={"<span style=\"color: rgb(0, 0, 0);\">Cena: 56 Kč /ks</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Věneček s vanilkovým krémem</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/7dd7fc6e5b69479388a4821affb1bff2_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/7dd7fc6e5b69479388a4821affb1bff2_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/7dd7fc6e5b69479388a4821affb1bff2_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/7dd7fc6e5b69479388a4821affb1bff2_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/7dd7fc6e5b69479388a4821affb1bff2_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/7dd7fc6e5b69479388a4821affb1bff2_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 lh--08" content={"<span style=\"color: rgb(0, 0, 0);\">Cena: 39 Kč / ks</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny:&nbsp;(1,3,7)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Kremrole</span>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/def33f8308364978960aca31cddc5ef0_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/def33f8308364978960aca31cddc5ef0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/def33f8308364978960aca31cddc5ef0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/def33f8308364978960aca31cddc5ef0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/def33f8308364978960aca31cddc5ef0_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/def33f8308364978960aca31cddc5ef0_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 35 Kč / ks</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny:&nbsp;(1,3,7)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":431}} content={"<span style=\"font-weight: bold;\">Jablečný závin</span><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/858633bfd9084e27950fea391ba6c47b_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/858633bfd9084e27950fea391ba6c47b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/858633bfd9084e27950fea391ba6c47b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/858633bfd9084e27950fea391ba6c47b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/858633bfd9084e27950fea391ba6c47b_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/858633bfd9084e27950fea391ba6c47b_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 390 Kč (cca 12 ks)</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":431}} content={"<span style=\"font-weight: bold;\">Cookies</span><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/4282c56adbbd4665951c517276b3a5e7_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/4282c56adbbd4665951c517276b3a5e7_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/4282c56adbbd4665951c517276b3a5e7_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/4282c56adbbd4665951c517276b3a5e7_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/4282c56adbbd4665951c517276b3a5e7_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/4282c56adbbd4665951c517276b3a5e7_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 33 Kč / ks</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Tmavé s bílou čokoládou | Světlé arašídové s tmavou čokoládou\nAlergeny: (1,3,5,7,8)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":431}} content={"<span style=\"font-weight: bold;\">Brownies</span><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/18947/39f85c2e67c24c869ac4f5196de41c1c_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/39f85c2e67c24c869ac4f5196de41c1c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/39f85c2e67c24c869ac4f5196de41c1c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/39f85c2e67c24c869ac4f5196de41c1c_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/39f85c2e67c24c869ac4f5196de41c1c_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/39f85c2e67c24c869ac4f5196de41c1c_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box text-box--right fs--18 w--400 ls--0 lh--08" content={"<span style=\"color: rgb(8, 8, 8);\">Cena: 390 Kč (plech cca 12 ks)</span>"}>
              </Text>

              <Text className="text-box fs--12" content={"Alergeny: (1,3,7) | možno bezlepkové"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--center fs--16" content={"<span style=\"color: var(--color-custom-2);\">Uvedené ceny jsou bez DPH, platné pouze pro objednávky po předchozí domluvě s doručením nebo při osobním odběru.&nbsp;&nbsp;</span><span style=\"font-weight: 700;\"><span style=\"color: var(--color-custom-2);\"><br></span><span style=\"color: rgb(255, 255, 255);\"><br>Aktuální nabídku našich dezertů najdete v našich provozovnách</span></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kosl5kjii8"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Dostali jste chuť na dort od nás?"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1083}} content={"<span style=\"color: rgb(0, 0, 0);\">Vyberte si z naší nabídky dortů nebo popište představu vašeho vysněného dortu a my se pokusíme splnit\nočekávání vás i vašich hostů. <br>Za kvalitou našich dortů a dezertů si stojíme,\npečeme jen z čerstvých a kvalitních surovin. Objednávku si následně můžete u\nnás vyzvednout v Bistru nebo vám ji doručíme až domů.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"s5rgf55x11"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Vaše jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"E-mailová adresa"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon"},{"name":"Předmět objednávky","type":"text","placeholder":"Mám zájem o "},{"name":"Doprava","type":"text","placeholder":"Objednávku si vyzvednu / chci doručit na adresu"},{"name":"Poznámky","type":"textarea","required":true,"placeholder":"Poznámky"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-177vxld css-25yn64 pb--60 pt--60" name={"4t6gasmb9l"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18947/68bffe15a2bd499f8e52a0f8e81adbaa_s=3000x_.jpg);
    }
  
background-position: 50% 68%;
    `}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={""}>
                  </Text>

                  <Text className="text-box text-box--center fs--20" content={"<span style=\"color: rgb(8, 8, 8); font-weight: bold;\">Nová budova\nNárodního muzea</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<span style=\"font-weight: bold;\">PO - NE</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"9:30 - 19:00"}>
                  </Text>

                  <Text className="text-box fs--18" content={""}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={""}>
                  </Text>

                  <Text className="text-box text-box--center fs--20" content={"<span style=\"color: rgb(8, 8, 8); font-weight: bold;\">Historická budova\nNárodního muzea</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<span style=\"font-weight: bold;\">PO - NE</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"10:00 – 18:00"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={""}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20" content={""}>
                  </Text>

                  <Text className="text-box fs--20" content={"<span style=\"color: rgb(8, 8, 8);\">Pro objednávky dortů a zákusků využijte náš e-mail info@bistronarodnimuzeum.cz</span>"}>
                  </Text>

                  <Text className="text-box fs--24" content={""}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--left" style={{"maxWidth":192}} alt={""} src={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">E-mail</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"info@bistronarodnimuzeum.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Telefon</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"+420 257 310 852"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Sledujte nás</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.facebook.com/bistronarodnimuzeum\" target=\"_blank\">Facebook</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.instagram.com/bistronarodnimuzeum/\" target=\"_blank\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}